export default {
  ADD_ITEM(state, item) {
    state.Complaints.unshift(item);
  },
  SET_Complaint(state,  Complaints) {
    state.Complaints =  Complaints;
  },
  UPDATE_Complaint(state,  Complaints) {
    const  ComplaintsIndex = state.Complaints.findIndex(p => p.Id ==  Complaints.Id);
    if (ComplaintsIndex != -1) {
      Object.assign(state. Complaints[ComplaintsIndex],  Complaints);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Complaints.findIndex(p => p.Id == itemId);
    state.Complaints.splice(ItemIndex, 1);
  }
};

