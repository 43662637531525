
import state from "./moduleComplaintState.js"
import mutations from "./moduleComplaintMutations.js"
import getters from "./moduleComplaintGetters.js"
import actions from './moduleComplaintActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
