ct<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">

    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="Complaints">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ Complaints.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : Complaints.length }} of {{ Complaints.length }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>

        </vs-dropdown>

      </div>

      <template slot="thead">
        <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("ComplaintText")}}</vs-th>
        <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("ReplyText")}}</vs-th>
        <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("ComplaintDate")}}</vs-th>
        <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("ReplyDate")}}</vs-th>
        <!-- <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("ComplaintImage")}}</vs-th> -->
        <!-- <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("ComplaintWaitingNumperDay")}}</vs-th> -->
        <!-- <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("ReplyWaitingNumperDay")}}</vs-th> -->
        <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("FiledComplaint")}}</vs-th>

        <vs-th style="font-size: 16px;font-family:almarai ; color: crimson;">{{$t('Action')}}</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>

            <tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" @click="editData(tr)" >

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.ComplaintText }}</p>
              </vs-td>
               <vs-td>
                <p class="product-name font-medium truncate">{{ tr.ReplyText }}</p>
              </vs-td>
               <!-- <vs-td>
                <p class="product-name font-medium truncate">{{ tr.NameAR }}</p>
              </vs-td>
               <vs-td>
                <p class="product-name font-medium truncate">{{ tr.NameAR }}</p>
              </vs-td>
               <vs-td>
                <p class="product-name font-medium truncate">{{ tr.NameAR }}</p>
              </vs-td> -->
               <vs-td>
                <p class="product-name font-medium truncate">{{ tr.ComplaintDate }}</p>
              </vs-td>
               <vs-td>
                <p class="product-name font-medium truncate">{{ tr.ReplyDate }}</p>
              </vs-td>
               <vs-td>
                <p class="product-name font-medium truncate">{{ tr.FiledComplaint }}</p>
              </vs-td>
        

           <vs-td class="whitespace-no-wrap flex items-center">
  <vs-tooltip text="تفاصيل">
    <feather-icon 
      icon="InfoIcon" 
      svgClasses="w-5 h-5 hover:text-danger stroke-current"      
      @click.stop="viewDetail(tr)" 
    />
  </vs-tooltip>
  <vs-tooltip text="رد">
    <feather-icon 
      icon="CornerUpLeftIcon" 
      svgClasses="w-5 h-5 hover:text-primary stroke-current" 
      @click.stop="Reply(tr)" 
    />
  </vs-tooltip>
</vs-td>

            </tr>
            
          </tbody>
        </template>
    </vs-table>

    <template>
  <vs-popup title="تفاصيل المشكله" classContent="popup-example" :active.sync="popupActive">
  <div class="vx-row" style="padding-right:20px">
    <div class="vx-col w-full">
      <table style="width: 100%; border-collapse: collapse;">
        <tr>
          <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; text-align: right;">
            {{$t("ComplaintText")}}
          </th>
          <td style="border: 1px solid #ddd; padding: 8px;">
            {{complientObj.ComplaintText}}
          </td>
        </tr>
        <tr>
          <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; text-align: right;">
            {{$t("ReplyText")}}
          </th>
          <td style="border: 1px solid #ddd; padding: 8px;">
            {{complientObj.ReplyText}}
          </td>
        </tr>
        <tr>
          <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; text-align: right;">
            {{$t("ComplaintDate")}}
          </th>
          <td style="border: 1px solid #ddd; padding: 8px;">
            {{complientObj.ComplaintDate}}
          </td>
        </tr>
        <tr>
          <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; text-align: right;">
            {{$t("ReplyDate")}}
          </th>
          <td style="border: 1px solid #ddd; padding: 8px;">
            {{complientObj.ReplyDate}}
          </td>
        </tr>
        <tr>
          <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; text-align: right;">
            {{$t("ComplaintWaitingNumperDay")}}
          </th>
          <td style="border: 1px solid #ddd; padding: 8px;">
            {{complientObj.ComplaintWaitingNumperDay}}
          </td>
        </tr>
        <tr>
          <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; text-align: right;">
            {{$t("ReplyWaitingNumperDay")}}
          </th>
          <td style="border: 1px solid #ddd; padding: 8px;">
            {{complientObj.ReplyWaitingNumperDay}}
          </td>
        </tr>
        <tr>
          <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; text-align: right;">
            {{$t("ComplaintImage")}}
          </th>
          <td style="border: 1px solid #ddd; padding: 8px;">
           <img :src="baseURL + complientObj.ComplaintImage" alt="slide" style="width:100px; height:100px" />
          </td>
        </tr>
      </table>
    </div>
  </div>
</vs-popup>

</template>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import DataViewSidebar from "./DataViewSidebarComplaint.vue";
import moduleComplaint from "@/store/Complaint/moduleComplaint.js";
// import axios from "@/axios.js";

export default {
  components: {
    DataViewSidebar
  },
  data() {
    return {
      popupActive:false,
      complientObj:{},
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Complaints() {
     debugger;
       return this.$store.state.ComplaintList.Complaints;
   },
  },
  methods: {
    addNewData() {
      debugger;
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
      viewDetail(data) {
        debugger;
        console.log(data);
        this.popupActive=true,
        this.complientObj=data
    //    this.$store.dispatch('ComplaintList/GetItemByID', data)
    // .then(response => {
    //   // Store the fetched data in Vuex or sessionStorage if needed
    //   console.log(response.data)
    //this.$router.push(`/Notification/ComplaintDetails/${data}`);
     // this.$router.push({ name: 'ComplaintDetails', params: { Id: data } });
    // })
    // .catch(error => {
    //   console.error('Error fetching complaint details:', error);
    // });
    },
    // deleteData(data) {
    //   this.$store.dispatch("ComplaintList/removeItem", data).then(() => {
    //     this.$store.dispatch("ComplaintList/fetchDataListItems");
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       this.$store.dispatch("ComplaintList/fetchDataListItems");
    //     });
    // },
    Reply(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title:"Confirm",
        text: "Are you sure you want to delete this record ?",
        accept: this.acceptAlert
      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({ time:8000,
        color: "success",
        title: "Deleted record",
        text: "Successfully Deleted",
      });
      //this.GetCountries();
    },
    
  },
  created() {
    if (!moduleComplaint.isRegistered) {
      this.$store.registerModule("ComplaintList", moduleComplaint);
      moduleComplaint.isRegistered = true;
    }
    debugger;
    this.$store.dispatch("ComplaintList/fetchDataListItems");
    //this.GetCountries();

  },
  mounted() {
    this.isMounted = true;
    //this.GetCountries();
  }
};
</script>

<style lang="scss">
.bordered-table {
  width: 100%;
  border-collapse: collapse;
}

.bordered-table th, .bordered-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.bordered-table th {
  background-color: #f2f2f2;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
